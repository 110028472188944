import { renderIndividualProductTemplateBlocks } from '../util/BlockUtil';

const Home = ({
  data,
  lang,
  width,
  global,
  popular_downloads_posts,
  related_content,
  tags,
}) => {
  return (
    <div id="home-template" className="home-template">
      {renderIndividualProductTemplateBlocks({
        data,
        width,
        lang,
        global,
        popular_downloads_posts,
        related_content,
        tags,
      })}
    </div>
  );
};

export default Home;
